




























import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { PaginatedBadges } from '@/models/badges';
import moment from 'moment';

@Component({})
export default class BadgeTable extends Vue {
  get moment() {
    return moment;
  }

  @Prop()
  tableData!: PaginatedBadges['data'];

  @Emit(`edit`)
  editItem(item: any) {
    return item;
  }
}
