




































import { Vue, Component } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BadgeTable from '@/components/badges/BadgeTable.vue';
import EmptyPlaceholder from '@/components/common/EmptyPlaceholder.vue';
import { BadgeDetails } from '@/models/badges';
import { BadgeStores } from '@/store/badges';
import { PaginationDetails } from '@/models/generic';
import PaginationControls from '@/components/common/PaginationControls.vue';
import SearchBar from '@/components/common/SearchBar.vue';

@Component({
  components: {
    Page,
    BadgeTable,
    EmptyPlaceholder,
    PaginationControls,
    SearchBar,
  },
})
export default class BadgeManagementPage extends Vue {
  badgeStore = BadgeStores;

  currentPage = 1;
  currentLimit = 10;
  searchText = '';

  get badges() {
    return this.badgeStore?.badges;
  }

  mounted() {
    this.fetchData();
  }

  fetchData() {
    this.badgeStore.getBadges({
      page: this.currentPage,
      limit: this.currentLimit,
      keyword: this.searchText,
    });
  }

  onChangePage(params: PaginationDetails) {
    this.currentPage = params.page;
    this.currentLimit = params.limit;
    this.fetchData();
  }

  handleSearch(keyword: string) {
    this.searchText = keyword;
    this.fetchData();
  }

  handleEdit(data: BadgeDetails) {
    this.$router.push({ path: `badges/${data.id}/edit` });
  }
}
